import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";

import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import twitterIcon from "../assets/img/nav-icon1.svg"; // Update with the correct path
import facebookIcon from "../assets/img/nav-icon2.svg"; // Update with the correct path
import instagramIcon from "../assets/img/nav-icon3.svg"; // Update with the correct path

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>Thank you for visiting my portfolio! If you want me to join your team, feel free to reach out.</p>
        {/* <div className="social-links">
          <a href="https://github.com/yourusername" target="_blank" rel="noopener noreferrer" className="social-icon">
            <img src="./assets/img/github-icon.png" alt="GitHub" />
          </a>
          <a href="https://www.linkedin.com/in/yourusername/" target="_blank" rel="noopener noreferrer" className="social-icon">
            <img src="./assets/img/linkedin-icon.png" alt="LinkedIn" />
          </a>
          <a href="mailto:your.email@example.com" className="social-icon">
            <img src="./assets/img/email-icon.png" alt="Email" />
          </a>
        </div> */}
        <p className="footer-note">© {new Date().getFullYear()} Tanzim Chowdhury</p>
      </div>
    </footer>
    // <footer className="footer">
    //   <Container>
    //     <Row className="align-items-center">
    //       <MailchimpForm />
    //       <Col size={12} sm={6}>
    //         <img src={logo} alt="Logo" />
    //       </Col>
    //       <Col size={12} sm={6} className="text-center text-sm-end">
    //         <div className="social-icon">
    //           <a href="#"><img src={navIcon1} alt="Icon" /></a>
    //           <a href="#"><img src={navIcon2} alt="Icon" /></a>
    //           <a href="#"><img src={navIcon3} alt="Icon" /></a>
    //         </div>
    //         <p>Copyright 2022. All Rights Reserved</p>
    //       </Col>
    //     </Row>
    //   </Container>
    // </footer>
  )
}
