import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/medium/ci_cd.jpg";
import projImg2 from "../assets/img/medium/elastic.jpg";
import projImg3 from "../assets/img/medium/hashing.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Automate CI/CD 2024",
      description: "Seamlessly Deploy Your Node.js Application to Heroku Using GitHub Actions",
      imgUrl: projImg1,
      link: "https://medium.com/@tanzim3421/automate-ci-cd-2024-seamlessly-deploy-your-node-js-application-to-heroku-using-github-actions-df160134609f"
    },
    {
      title: "Setting up Elasticsearch and Kibana on Windows",
      description: "A step-by-step guide",
      imgUrl: projImg2,
      link: "https://medium.com/@tanzim3421/setting-up-elasticsearch-and-kibana-on-windows-a-step-by-step-guide-5b8368e2dc77"
    },
    {
      title: "Understanding Hashing",
      description: "The Backbone of Data Integrity and Security",
      imgUrl: projImg3,
      link: "https://medium.com/@tanzim3421/understanding-hashing-the-backbone-of-data-integrity-and-security-92e6e5fcdee3"
    }
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            {/* <TrackVisibility> */}
            {/* {({ isVisible }) => */}
            <div>
              {/* <h2>Medium Articles for Professionals developers</h2> */}
              <h2>Practical Solutions for Software Engineers</h2>
              <p>Stay ahead of the curve with me! I'm passionate about new tech and helping fellow enginners overcome the challenges that come with it. Through my articles, I share my expertise, experiences, and enthusiasm to make development fun, empowering, and easy!</p>
              <Tab.Container id="projects-tabs" defaultActiveKey="first">
                {/* <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Meduim Articles</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Tab 2</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Tab 3</Nav.Link>
                      </Nav.Item>
                    </Nav> */}
                <Tab.Content id="slideInUp">
                  <Tab.Pane eventKey="first">
                    <Row>
                      {
                        projects.map((project, index) => {
                          return (
                            <ProjectCard
                              key={index}
                              {...project}
                            />
                          )
                        })
                      }
                    </Row>
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="section">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                      </Tab.Pane> */}
                </Tab.Content>
              </Tab.Container>
            </div>
            {/* } */}
            {/* </TrackVisibility> */}
          </Col>
        </Row>
      </Container>

      {/* <img className="background-image-right" src={colorSharp2}></img> */}
    </section>
  )
}
